import clsx from "clsx";
import { PropsWithChildren } from "react";

type TextNoWrapEllipsisProps = {
  className?: string;
};

export function TextNoWrapEllipsis({
  className,
  ...props
}: PropsWithChildren<TextNoWrapEllipsisProps>) {
  return (
    <span
      className={clsx(
        className,
        "block overflow-hidden overflow-ellipsis whitespace-nowrap",
      )}
    >
      {props.children}
    </span>
  );
}
