"use client";
import React from "react";
import GeneralError from "@/src/components/GeneralError";
import { ROUTE_HOME } from "@/constants/routes";
import { LinkButton } from "@/src/components/buttons/components/LinkButton";
import { useMountEffect } from "@react-hookz/web";
import { BaseLayout } from "./components/BaseLayout";
import { SiteWrapper } from "@/src/components/grid/components/SiteWrapper";
import { DarkThemeStyle } from "@/src/components/Layout/DarkThemeStyle";
import { GridBackground } from "@/src/components/grid/components/GridBackground";
import { ERROR_CODES } from "@/libs/error/types";
import { Button } from "nvs-design-system/dist/Button_DEPRECATED";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";

export default function Error({
  error,
  reset, //TODO: implement try again with new design
}: {
  error: Error & { digest?: string; code?: number };
  reset: () => void;
}) {
  useMountEffect(() => {
    if (window !== undefined) {
      window.newrelic?.noticeError({ ...error });
    }
  });
  const { reset: resetErrorBoundary } = useQueryErrorResetBoundary();
  return (
    <BaseLayout>
      <DarkThemeStyle />
      <GridBackground className="font-roobert flex flex-grow w-full  md:justify-center justify-start">
        <SiteWrapper
          className="flex flex-grow flex-col"
          innerClassName="flex flex-grow flex-col"
        >
          <GeneralError className="h-fit pt-20 pb-20">
            {error?.code === ERROR_CODES.NOT_FOUND ? (
              <LinkButton
                size={"sm"}
                href={ROUTE_HOME}
                className={"w-fit pr-16 md:text-xl"}
              >
                Continue Exploring
              </LinkButton>
            ) : (
              <Button
                size="sm"
                className={"md:max-w-[327px] max-w-[249px] md:text-xl"}
                onClick={() => {
                  reset();
                  resetErrorBoundary();
                }}
              >
                Try Again
              </Button>
            )}
          </GeneralError>
        </SiteWrapper>
      </GridBackground>
    </BaseLayout>
  );
}
