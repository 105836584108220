import clsx from "clsx";
import { IconSize } from "../IconContainer";
import { ButtonSize, ButtonSizes, ButtonType, ButtonTypes } from "./types";

// NOTE:
// These are shared between <Button> and <LinkButton>

export const MAP_BUTTON_TYPE_TO_CLASSNAME = {
  [ButtonType.primary]: clsx(
    // Disable iOS borders
    "border border-transparent",
    "text-left p-2.5",
    "bg-green-500 text-gray-950",
    // Dark Theme
    "dark:border-1 dark:border-green-500",
    // Better hover for no hover on touch devices
    "betterhover:hover:bg-gray-950 betterhover:hover:text-green-500",
    // Active styles for touch devices
    "active:bg-gray-950 active:text-green-500",
    // Disabled styles
    "disabled:bg-gray-200 disabled:text-gray-425 disabled:pointer-events-none dark:disabled:border-transparent dark:disabled:bg-gray-800 dark:disabled:text-gray-350",
  ),
  [ButtonType.secondary]: clsx(
    // Disable iOS borders
    "border border-transparent",
    "text-left p-2.5",
    "bg-white text-black",
    // Dark Theme
    "dark:bg-black dark:text-white",
    // Better hover for no hover on touch devices
    "betterhover:hover:text-green-500 dark:betterhover:hover:border-green-500",
    // Active styles for touch devices
    "active:bg-black active:text-green-500",
    // Disabled styles
    "disabled:bg-gray-800 disabled:text-gray-350 disabled:pointer-events-none dark:disabled:border-transparent dark:disabled:text-gray-350",
    // Dark with better hover
    "dark:betterhover:hover:bg-green-500 dark:betterhover:hover:text-gray-950",
  ),
  // NOTE: these tertiary styles are incomplete but we need an additional button type to support the new filter menu
  // and we don't have full clarity on the design system changes yet so just adding what's needed for now
  [ButtonType.tertiary]: clsx(
    "text-left p-2.5",
    "bg-white dark:bg-gray-950 text-gray-950 dark:text-white",
    // Border Theme
    "border border-1 border-gray-500",
    // Better hover for no hover on touch devices
    "betterhover:hover:text-blue-500 dark:betterhover:hover:text-green-500 betterhover:hover:border-blue-500 dark:betterhover:hover:border-green-500",
    // Active styles for touch devices
    "active:bg-gray-950 active:text-green-500",
    // Disabled styles
    "disabled:bg-gray-800 disabled:text-gray-350 disabled:pointer-events-none dark:disabled:text-gray-350",
  ),
  [ButtonType.tab]: clsx(
    // Disable iOS borders
    "border border-transparent",
    "text-center py-1 px-6 text-gray-950 rounded-full",
    // selected state
    "selected:bg-white selected:bg-gray-950 selected:text-white",
    // selected hovers
    "selected:betterhover:hover:bg-green-500 selected:betterhover:hover:text-gray-950 selected:betterhover:hover:border-transparent",
    // selected state for dark theme
    "selected:dark:text-gray-950 selected:dark:betterhover:hover:text-gray-950 selected:dark:bg-white",
    // Better hover for no hover on touch devices
    "betterhover:hover:border-gray-950",
    // Active styles for touch devices
    "active:bg-gray-950 active:text-green-500",
    // Dark Theme
    "dark:text-white dark:disabled:border-transparent",
    // Dark with better hover
    "dark:betterhover:hover:border-green-500 dark:betterhover:hover:text-green-500",
  ),
};

export const MAP_BUTTON_SIZE_TO_CLASSNAME = {
  [ButtonSize.sm]: "text-md",
  [ButtonSize.md]: "text-xl",
  [ButtonSize.lg]: "text-3xl",
};

export const SHARED_BUTTON_CLASSNAMES = clsx(
  // assumes flexbox display style
  "items-center justify-between gap-2",
  "transition-colors duration-150 ease-in-out",
  "text-left uppercase font-medium leading-none",
  // :focus-visible for keyboard event focus only
  "focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500",
  "font-roobert",
);

export function getClassNamesByType(
  buttonType: ButtonTypes,
  size: ButtonSizes,
  isLoading = false,
) {
  return clsx(
    isLoading && "pointer-events-none",
    MAP_BUTTON_TYPE_TO_CLASSNAME[buttonType],
    MAP_BUTTON_SIZE_TO_CLASSNAME[size],
    SHARED_BUTTON_CLASSNAMES,
  );
}

const MAP_BUTTON_SIZE_TO_ICON_SIZE = {
  [ButtonSize.sm]: IconSize.sm,
  [ButtonSize.md]: IconSize.base,
  [ButtonSize.lg]: IconSize.md,
};
export function getIconSizeByButtonSize(size: ButtonSizes) {
  return MAP_BUTTON_SIZE_TO_ICON_SIZE[size];
}
