import { PropsWithChildren } from "react";
import clsx from "clsx";
import { Spinner } from "../Spinner";
import { IconContainer } from "../IconContainer";
import { TextNoWrapEllipsis } from "../TextNoWrapEllipsis";
import { getClassNamesByType, getIconSizeByButtonSize } from "./helpers";
import { ButtonProps, ButtonSize, ButtonType } from "./types";

export function Button({
  className,
  icon,
  size = ButtonSize.md,
  buttonType = ButtonType.primary,
  display = "inline-flex",
  isLoading = false,
  disabled,
  ...props
}: PropsWithChildren<ButtonProps>) {
  const hasIcon = icon ?? isLoading;

  return (
    <button
      className={clsx(
        display,
        getClassNamesByType(buttonType, size, isLoading),
        className,
      )}
      disabled={disabled}
      {...props}
    >
      <TextNoWrapEllipsis>{props.children}</TextNoWrapEllipsis>
      {hasIcon && (
        <IconContainer
          size={getIconSizeByButtonSize(size)}
          display="inline-block"
          className="ml-auto shrink-0"
        >
          {isLoading ? <Spinner /> : icon}
        </IconContainer>
      )}
    </button>
  );
}
