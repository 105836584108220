import React from "react";

export enum ButtonType {
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
  tab = "tab",
}

export enum ButtonSize {
  sm = "sm",
  md = "md",
  lg = "lg",
}

export type ButtonSizes = keyof typeof ButtonSize;
export type ButtonTypes = keyof typeof ButtonType;

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  buttonType?: ButtonTypes;
  size?: ButtonSizes;
  icon?: React.ReactNode;
  display?: string;
  isLoading?: boolean;
};
